@font-face {
    font-family: 'Coldiac';
    src: url('./Coldiac.ttf') format('ttf');
    /* font-weight: normal;
    font-style: normal; */
  }

  @font-face {
    font-family: 'Coldiac Italic';
    src: url('./Coldiac-Italic.otf') format('otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Light.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }