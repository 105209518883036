/* New Styles Natural Balance */
.scrollable-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Para scroll suave en iOS */
}

.scrollable-row {
  display: flex;
  flex-wrap: nowrap;
}

.scrollable-row .col {
  flex: 0 0 auto;
}
/* New Styles Season */

.start-55-bg {
  left: 55%;
}
.end-55-bg {
  right:  55%;
}
@media (max-width: 768px) {
  .h-100-nature-balance {
    height: 100%;
  }

  .start-55-bg {
    left: 50%;
  }
  .end-55-bg {
    right:  50%;
  }

}


.slideshow-season {
  height: 34.5rem;
}

.slideshow-season.type3 {
  height: 26.25rem;
}

.slideshow-season.type4 {
  height: 26.25rem;
}

@media (min-width: 992px) {
  .slideshow-season.type2 {
    height: 56rem;
  }

  .slideshow-season.type3 {
    height: 35rem;
  }

  .slideshow-season.type4 {
    height: 37.5rem;
  }

  .slideshow-season.slideshow-lg {
    height: 47.5rem;
  }

  .slideshow-season.slideshow-md {
    height: 43.75rem;
  }
}


.translate-middle-season {
  transform: translate(-50%, -60%) !important;
}


.translate-middle-kids {
  transform: translate(125%, -140%) !important;
}


/* Bloques finales */
.sale-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 100%;
}

.sale-text h1 {
  font-size: 4rem; /* Tamaño grande del título */
  margin-bottom: 0.5rem;
}

.sale-text p {
  font-size: 1.2rem; /* Tamaño apropiado para el texto descriptivo */
}

/* Asegúrate que las imágenes se ajusten bien en su contenedor */
.img-fluid {
  width: 100%;
  height: auto;
  display: block;
}

/* Estilos para el texto bajo la imagen de fibras */
.text-center {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.5rem;
}

/* Estilos para el footer nuevo */

.footer-placeholder::placeholder {
  color: #171717;
}
.navigation__item:hover .box-menu {
  display: block; /* Muestra el menú cuando el elemento padre es hovered */
}